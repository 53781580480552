import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import axios from 'axios'
import queryString from 'query-string'

import Layout from '../../../../components/layout'
import SEO from '../../../../components/seo'
import { FullScreenHeroSection } from '../../../../components/common/hero-section'

const CompletingBetaSignupPage = ({
  data: {
    site: {
      siteMetadata: { apiUrl },
    },
    heroImage,
  },
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [hasFailed, setHasFailed] = useState(false)

  useEffect(() => {
    if (window['location']) {
      const parsed = queryString.parse(window['location'].search)
      axios
        .get(`${apiUrl}/oauth/twitch/beta/callback?code=${parsed.code}`)
        .then(result => {
          setHasFailed(false)
          setIsLoading(false)
        })
        .catch(error => {
          setHasFailed(true)
          setIsLoading(false)
        })
    }
  }, [])

  let Title = <h1>Completing Beta Signup</h1>

  let Paragraph = (
    <p>
      If this takes longer than 30 seconds or so, try navigating back and trying
      again. If it stalls again, let us know on Discord. (link coming soon)
    </p>
  )

  if (!isLoading && hasFailed) {
    // failed
    Title = <h1>Sorry, the signup process failed.</h1>
    Paragraph = (
      <p>
        We are still working out some of the bugs, so please give us some time
        to investigate.
      </p>
    )
  } else if (!isLoading && !hasFailed) {
    // succeeded
    Title = <h1>Success!</h1>
    Paragraph = (
      <p>
        We still need to finish up some things for the Beta. We will be emailing
        you when things are ready.
      </p>
    )
  }

  return (
    <Layout>
      <SEO title="Completing Beta Signup" />
      <BackgroundImage
        fluid={{
          base64: heroImage.childImageSharp.lowRes.dataURI,
          src: heroImage.childImageSharp.hiRes.dataURI,
        }}
      >
        <FullScreenHeroSection>
          {Title}
          {Paragraph}
        </FullScreenHeroSection>
      </BackgroundImage>
    </Layout>
  )
}

export default CompletingBetaSignupPage

export const pageQuery = graphql`
  query($SQIP_HERO_SHAPE_COUNT: Int, $SQIP_THUMB_SHAPE_COUNT: Int) {
    heroImage: file(relativePath: { regex: "/parrot-feathers.jpeg/" }) {
      ...HeroImage
    }
    site(siteMetadata: {}) {
      siteMetadata {
        apiUrl
      }
    }
  }
`
